import { commonConfig } from '@/config'

const dateFormater = (value, type = 'default') => {
  const date = new Date(value)
  if (type === 'partial') return `${date.getDate()} ${commonConfig.months[date.getMonth()]}`
  return `${date.getDate()} ${commonConfig.months[date.getMonth()]} ${date.getFullYear()}`
}

const timeFormater = value => {
  const date = new Date(value)
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString()
  return `${hours}.${minutes}`
}

export {
  dateFormater,
  timeFormater
}
