<template>
  <div
    @click="handleRedirect"
    :class="['w-full bg-white shadow-lg rounded-lg relative my-4', { 'border-red-600': isYoutube}]"
  >
    <img class="w-full h-60 rounded-t-lg object-cover object-top"
         :src="article.imageUrl"
         :alt="article.title"
          @error="handleFailImage"
         >
    <img v-if="isYoutube" class="w-9 h-9 absolute right-4 bottom-4" src="~@/assets/icons/youtube.svg" alt="youtube">
    <img v-if="isPdf" class="w-9 h-9 absolute right-4 bottom-4" src="~@/assets/icons/menu/pdf-blue.svg" alt="pdf">
    <div class="flex flex-col p-4 w-full">
      <div class="text-sm text-gray-400 text-right">{{ friendlyDate }}</div>
      <div class="text-lg font-bold text-gray-800 w-5/6">{{ article.title }}</div>
      <div class="text-base text-gray-400 w-5/6">{{ article.summary }}</div>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
import { homeConfig } from '@/config'
import PlaceholderImage from '@/assets/img/placeholder.jpeg'

export default {
  name: 'ArticleCard',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    friendlyDate () {
      return dateFormater(this.article.date)
    },
    isYoutube () {
      return this.article.type === homeConfig.articleType.youtube
    },
    isPdf () {
      return this.article.type === homeConfig.articleType.pdf
    }
  },
  methods: {
    handleRedirect () {
      this.$router.push({
        name: 'DetailArticle',
        params: {
          id: this.article.id
        }
      })
    },
    handleFailImage (e) {
      e.target.src = PlaceholderImage
    }
  }
}
</script>

<style scoped>

</style>
